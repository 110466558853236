/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'typeface-open-sans';
import 'typeface-lato';

import './src/scss/main.scss';
import $ from 'jquery';
import 'bootstrap/js/dist/util.js';
import 'bootstrap/js/dist/modal.js';

window.$ = window.jQuery = $;

require('jquery-colorbox/example3/colorbox.css');
require('jquery-colorbox/jquery.colorbox');

String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};
